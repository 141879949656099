<template>
  <div>
    <v-container fluid>
      <v-card>
        <dialog-manif-destinatario :dialog="exibirDialogManifDestinatario" :tipo="tipoManifestacao" :nota="notaManifestar" :idDestinada="idDestinada" @fechar="exibirDialogManifDestinatario = false" @enviarManifestacao="enviarManifestacao"></dialog-manif-destinatario>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarDestinadas($event)" max-width="300px" prepend-inner-icon="search" ></v-text-field>
          <v-spacer></v-spacer>
          <v-radio-group class="ml-5" hide-details row v-model="filtro" @change="carregarDestinadas(filtro)" >
            <v-radio label="Pendentes" value="pendente"></v-radio>
            <v-radio label="Manifestadas" value="manifestada"></v-radio>
          </v-radio-group>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table item-key="_id" :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="destinadas" :loading="carregando">
          <template v-slot:item="props">
            <tr @click="props.expand(!props.isExpanded)" style="cursor:pointer">
              <td>{{ numeroNota(props.item.nota.chave) }}</td>
              <td>{{ props.item.nota.nome }}</td>
              <td class="text-no-wrap">{{ props.item.nota.cnpj | formatarCnpj }}</td>
              <td>{{ props.item.nota.ie }}</td>
              <td>{{ props.item.nota.data_emissao | formatarData }}</td>
              <td class="text-right">{{ Number(props.item.nota.valor) | formatarMoeda }}</td>
              <td class="text-center"><v-chip pill outlined><v-avatar left :color="definirCor(props.item.nota.situacao)">{{definirLetra(props.item.nota.situacao)}}</v-avatar>{{ descricaoSituacao(props.item.nota.situacao)}}</v-chip></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item v-show="props.item.status == 'pendente'" @click="abrirDialogManifDest(constantes.MANIF_CONFIRMACAO, props.item)">
                      <v-list-item-icon><v-icon>thumb_up_alt</v-icon></v-list-item-icon>
                      <v-list-item-content>Confirmação da Operação</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="props.item.status == 'pendente'" @click="abrirDialogManifDest(constantes.MANIF_CIENCIA, props.item)">
                      <v-list-item-icon><v-icon>sentiment_very_satisfied</v-icon></v-list-item-icon>
                      <v-list-item-content>Ciência da Operação</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="props.item.status == 'pendente'" @click="abrirDialogManifDest(constantes.MANIF_DESCONHECIMENTO, props.item)">
                      <v-list-item-icon><v-icon>sentiment_very_dissatisfied</v-icon></v-list-item-icon>
                      <v-list-item-content>Desconhecimento da Operação</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="props.item.status == 'pendente'" @click="abrirDialogManifDest(constantes.MANIF_NAO_REALIZADA, props.item)">
                      <v-list-item-icon><v-icon>thumb_down_alt</v-icon></v-list-item-icon>
                      <v-list-item-content>Operação não Realizada</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="props.item.status == 'manifestada'" @click="downloadNfe(props.item.nota)">
                      <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                      <v-list-item-content>Download NF-e</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="px-0">
                <v-card>
                  <v-card-title>Informações Adicionais</v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined label="Chave de Acesso" :value="item.nota.chave | formatarChave" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined label="Protocolo" v-model="item.nota.protocolo" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined label="Data da Autorização" :value="item.nota.data_autorizacao | formatarDataHora" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import DialogManifDestinatario from './DialogManifDestinatario';
import constantes from '@/util/constantes';

export default {

  components: {
      DialogManifDestinatario
  },

  data() {
    return {
      headers: [
        { text: "Número", value: "nota.chave", sortable: false },
        { text: "Emitente", value: "nota.nome", sortable: false },
        { text: "CNPJ", value: "nota.cnpj", sortable: false },
        { text: "IE", value: "nota.ie", sortable: false },
        { text: "Data", value: "nota.data_emissao", sortable: false },
        { text: "Valor", value: "nota.valor", sortable: false, align:"right" },
        { text: "Status", value: "nota.situacao", sortable: false, align: "center" },
        { text: "Ações", value: "name", align: "center", sortable: false }
      ],
      busca: '',
      filtro: 'pendente',
      constantes: constantes,
      exibirDialogManifDestinatario: false,
      tipoManifestacao: '',
      notaManifestar: '',
      idDestinada: '',
      options: {
        itemsPerPage: 10,
        page: 1
      },
      footerOptions: {
        'items-per-page-options': constantes.ITEMS_POR_PAGINA 
      }
    };
  },

  watch: {
    options: {
      handler () {
        this.carregarDestinadas();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('sefaz', {
      carregando: 'carregando',
      destinadas: 'destinadas',
      destinada: 'destinadaAtual',
      total: 'totalDestinadas'
    })
  },

  methods: {
    
    ...mapActions('sefaz', {
        downloadNfe: 'downloadNfe'
    }),

    carregarDestinadas(){
      let newOptions = {
        ...this.options,
        status: this.filtro
      }
      this.$store.dispatch('sefaz/carregarDestinadas', newOptions);
    },

    limparBusca(){
      this.carregarDestinadas();
    },

    filtrarDestinadas(event){

      let newOptions = {
        ...this.options,
        query: this.busca,
        status: this.filtro
      }

      this.$store.dispatch('sefaz/carregarDestinadas', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    enviarManifestacao(manifestacao){

      this.exibirDialogManifDestinatario = false;
      this.$store.dispatch('sefaz/manifestacaoDestinatario', manifestacao);
    },

    abrirDialogManifDest(tpEvento, destinada){
      this.idDestinada = destinada._id;
      this.notaManifestar = destinada.nota;
      this.tipoManifestacao = tpEvento;
      this.exibirDialogManifDestinatario = true;
    },

    numeroNota(chave){
      if(chave){
        let numero = parseInt(chave.substr(25, 9), 10);
        let serie = parseInt(chave.substr(22, 3), 10);
        return numero + '-' + serie;
      }
      else{
        return '';
      }
    },

    definirCor(situacao){
      if(situacao == 1){
        return 'green';
      }
      else if(situacao == 2){
        return 'red';
      }
      else if(situacao == 3){
        return 'grey';
      }
    },

    definirLetra(situacao){
      if(situacao == 1){
        return 'A';
      }
      else if(situacao == 2){
        return 'D';
      }
      else if(situacao == 3){
        return 'C';
      }
    },

    descricaoSituacao(situacao){
      if(situacao == 1){
        return 'Autorizada';
      }
      else if(situacao == 2){
        return 'Denegada';
      }
      else if(situacao == 3){
        return 'Cancelada';
      }
    }
  }
};
</script>